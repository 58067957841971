import $ from 'jquery';

export default class Account{
    constructor () {
        this.jQuery = $;
        this.debounceTimer = null;
        this.init();
    }

    init() {
        $('input[name="date_end"]').css('display','none');

        $('input[name="date_start"]').on('change',function(){
            $('input[name="date_end"]').css('display','block');
        });

        $('.account-nav__link[href="#"]').on('click',function(e){
            e.preventDefault();
            $('.account-nav__item').removeClass('active');
            $(this).parent().addClass('active');
        });


        $('.account-tabs-nav__item').on('click',function(e){
            e.preventDefault();
            let tab = $(this).data('tab');

            $(this).parents('.account-box').find('.account-tabs-nav__item').removeClass('active');
            $(this).parents('.account-box').find('.account-tabs').removeClass('active');
            $(this).addClass('active');
            $(`.account-tabs[data-tab=${tab}]`).addClass('active');
        });

        // Écoute des champs d'entrée
        $('form[action="filter_credits"] input,form[action="filter_credits"] select').on('input change', () => {
            if (this.debounceTimer) {
                clearTimeout(this.debounceTimer); // Annuler le timeout précédent
            }

            // Démarrer un nouveau timeout
            this.debounceTimer = setTimeout(() => {
                this.filterCredits(); // Exécuter la fonction une fois le délai atteint
            }, 500);
        });

        this.contextualMenu();
    }

    contextualMenu() {
        $('.context-menu__trigger').on('click',function(e){
            e.preventDefault();
            $(this).parent().toggleClass('active');
        });
    }

    filterCredits() {
        const formData = {
            action: "filter_credits", // Nom de l'action côté serveur
            id: $('input[name="id"]').val(),
            nom: $('input[name="nom"]').val(),
            prenom: $('input[name="prenom"]').val(),
            montant: $('input[name="montant"]').val(),
            date_start: $('input[name="date_start"]').val(),
            date_end: $('input[name="date_end"]').val(),
            statut: $('select[name="statut"]').val()
        };

        $.ajax({
            url: ajaxurl.ajaxurl,
            type: "POST",
            data: formData,
            success: function (response) {
                $('tbody').html(response);
            },
            error: function (xhr, status, error) {
                console.error("Erreur AJAX :", error);
            }
        });
    }
}