export default class Faqlist {
    constructor(params) {
        this.params = params;
        this.slideToggle();
    }

    slideToggle() {
        $(this.params.element).find('.faqlist__item').addClass('faqlist__item--inactive');
        $(this.params.element).find('.faqlist__answer').slideUp();

        $(this.params.element).find('.faqlist__item').click(function() {
            $(this).toggleClass('faqlist__item--inactive').toggleClass('faqlist__item--active');
            $(this).find('.faqlist__answer').slideToggle();
        })
    }
}