import '../css/theme.scss';
import $ from 'jquery';
import Simulation from './components/simulation';
import DemandeForm from './components/form';
import Faqlist from './components/faqlist';
import Account from './components/account';
import Map from './components/map';

class Site {
    constructor () {
        this.jQuery = $;
        this.trigger();
        this.callback();
    }

    trigger() {
        const $body = $('body'),
            $breadcrumbForm = $('.breadcrumbForm');

        if ($('select').length > 0) $("select").not('.flagstrap').chosen({disable_search_threshold: 15});

        if($body.find('[data-simulation]').length > 0) {
            new Simulation({
                element : document.querySelector('[data-simulation]'),
                datas : window.dataSim,
            });
        }

        if($body.find('.simform').length > 0) {
            new DemandeForm();
        }

        if($body.find('#map').length > 0) {
            new Map();
        }

        if($body.find('.account-wrapper').length > 0) {
            new Account();
        }

        if($body.find('[data-faqlist]').length > 0) {
            new Faqlist({
                element : document.querySelector('[data-faqlist]'),
            });
        }

        if($body.find('.breadcrumbForm').length > 0) new MultiStep();

        $("#agencyList__select").chosen().change(e => document.location = $(e.target).val());


        if($(window).width() > 980) {
            $('.nav li.menu-item-75').hover(function(e){
                $(this).find('.sub-menu').addClass('sub-menu--active');
            }, function(){
                $('.menu__item--current').removeClass('menu__item--current');
                $('.sub-menu').removeClass('sub-menu--active');
            });
        }
    }

    callback() {
        if (window.location.hash == '#wpcf7-f116-o2') {
            $('.popup').addClass('active');
        }

        const $navTrigger = $('#js-navTrigger'),
            $closePopup = $('.popup__close'),
            $callmeback = $('.btn--callmeback'),
            $navContainer = $('.menu-main-container'),
            $navBody = $('body');

        $callmeback.on('click',(e)=>{
            e.preventDefault();
            e.stopPropagation();
            $('.popup').addClass('active');
        });

        $closePopup.on('click',(e)=>{
            e.preventDefault();
            e.stopPropagation();
            $('.popup').removeClass('active');
        });

        $('body').on('click', (e) => {
            $('.popup').removeClass('active');
        });

        $('.popup__wrap,.popup__wrap *:not(.popup__close):not(.popup__close *)').on('click', e => event.stopPropagation() );

        $navTrigger.click(e => {
            e.preventDefault();
            $navTrigger.toggleClass('active');
            $navContainer.toggleClass('active');
            $navBody.toggleClass('stop-scroll');
        });
    }
}

$(function(){
    new Site();
    if ($('.simulation__typeForm').length > 0) $('.simulation__typeForm').change();
});