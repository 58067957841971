export default class Map {
    constructor(params) {
        this.params = params;
        this.result_lat = null;
        this.result_lng = null  ;
        this.max_distance = null;
        this.new_marker = true;
        this.marker = null;
        this.symbol = {
            path: 'M25.4,0C16.2,0,8.8,7.4,8.8,16.5c0,11.7,13.2,28.3,16,31.8c0.3,0.3,0.8,0.3,1.1,0c2.8-3.5,16-20.2,16-31.8 C41.9,7.4,34.5,0,25.4,0z M25.4,22.1c-3.6,0-6.5-2.9-6.5-6.5s2.9-6.5,6.5-6.5s6.5,2.9,6.5,6.5S28.9,22.1,25.4,22.1z',
            circle : '',
            strokeOpacity: 1,
            strokeColor : '#258129',
            strokeWeight : 1,
            fillOpacity : 1,
            fillColor: '#258129',
            scale: 0.5
        };
        this.initMap();
    }

    initMap() {
        let $el = document.getElementById('map');
        let $markers = $el.querySelectorAll('.marker');
        $el.classList.add('load');

        let map = new google.maps.Map(document.getElementById('map'), {
            zoom: 8,
            mapId: 'MAP_AGENCES',
            center: {lat: 50.5149203, lng: 4.7043197},
        });
        const styledMapType = new google.maps.StyledMapType(
        [
            {
                "stylers": [
                    {
                        "hue": "#baf4c4"
                    },
                    {
                        "saturation": 10
                    }
                ]
            },
            {
                "featureType": "water",
                "stylers": [
                    {
                        "color": "#effefd"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]);
        map.mapTypes.set('styled_map', styledMapType);
        map.setMapTypeId('styled_map');

        let input = document.getElementById('address-customer');
        let autocomplete = new google.maps.places.Autocomplete(input);
        let geocoder = new google.maps.Geocoder();

        // add a markers reference
        map.markers = [];

        // add markers
        let index_marker = 0
        $markers.forEach((elem) => {
            this.add_marker(elem, map);
        });

        document.getElementById('submit-address').addEventListener('click', () => {
            this.max_distance = $('#js-maxDistance').val();
            this.geocodeAddress(geocoder, map);
            setTimeout(() => {
                for (let i = 0; i < map.markers.length; i++) {
                    let marker_lat = map.markers[i].position.lat();
                    let marker_lng = map.markers[i].position.lng();
                    let distance = this.getDistanceFromLatLonInKm(this.result_lat,this.result_lng,marker_lat,marker_lng);
                    let items = document.querySelectorAll('.agences__list__item');
                    if(distance > this.max_distance) {
                        map.markers[i].setMap(null);
                        items[i].classList.add('disabled');
                        items[i].classList.remove('actived');
                    }
                    else {
                        map.markers[i].setMap(map);
                        items[i].classList.remove('disabled');
                        items[i].classList.add('actived');
                    }
                }
            },500);
        });

        autocomplete.addListener('place_changed', () => {
            this.max_distance = $('#js-maxDistance').val();
            this.geocodeAddress(geocoder, map);
            setTimeout(() => {
                let first = true;
                let markers_distance = [];

                for (let i = 0; i < map.markers.length; i++) {
                    let marker_lat = map.markers[i].position.lat();
                    let marker_lng = map.markers[i].position.lng();
                    let distance = this.getDistanceFromLatLonInKm(this.result_lat,this.result_lng,marker_lat,marker_lng);
                    let items = document.querySelectorAll('.agences__list__item');
                    let selectElem = items[i];
                    selectElem.setAttribute('data-distance',distance);
                    selectElem.querySelector('.agences__list__item').textContent = distance.toFixed(2) + ' km';
                    markers_distance[i] = {
                        distance_elem : distance,
                        select_elem : selectElem
                    };

                    if(distance > this.max_distance) {
                        //map.markers[i].setMap(null);
                    }
                    else {
                        if(first === true) {
                            selectElem.classList.add('first');
                            first = false;
                        }
                    }
                }
                let distances = markers_distance.sort(function(a,b){
                    return (a.distance_elem - b.distance_elem);
                });

                for (let i = 0; i < map.markers.length; i++) {
                    distances[i].select_elem.classList.remove('first');
                    distances[i].select_elem.style.order = i;
                }
            },500);
        });
    }

    async add_marker($marker, map) {
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

        const priceTag = document.createElement("div");
        priceTag.className = "marker";
        let d = priceTag.appendChild(document.createElement("i"));
        d.className = "icon icon-location";
        console.log($marker.getAttribute('data-lat'));
        console.log($marker.getAttribute('data-lng'));
        let latlng = new google.maps.LatLng($marker.getAttribute('data-lat'), $marker.getAttribute('data-lng'));
        let marker = new google.maps.marker.AdvancedMarkerElement({
            position: latlng,
            map: map,
            content: priceTag,
        });

        map.markers.push(marker);

        // if marker contains HTML, add it to an infoWindow
        if ($marker.textContent) {
            // create info window
            let infowindow = new google.maps.InfoWindow({
                content: $marker.innerHTML
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', () => {
                infowindow.open(map, marker);
            });
        }
    }

    center_map(map) {
        // vars
        let bounds = new google.maps.LatLngBounds();

        // loop through all markers and create bounds
        $.each(map.markers, function (i, marker) {
            let latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
        });

        // only 1 marker?
        if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
        }
        else {
            // fit to bounds
            map.fitBounds(bounds);
        }

    }

    geocodeAddress(geocoder, resultsMap) {
        let address = document.getElementById('address-customer').value;
        geocoder.geocode({'address': address}, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                setTimeout(() => {
                    resultsMap.setCenter(results[0].geometry.location);
                    if(this.max_distance == 10) {
                        resultsMap.setZoom(14);
                    }
                    if(this.max_distance == 20) {
                        resultsMap.setZoom(12);
                    }
                    else if(this.max_distance == 50) {
                        resultsMap.setZoom(8);
                    }
                    else if(this.max_distance == 100) {
                        resultsMap.setZoom(7);
                    }
                    else if(this.max_distance == 200000) {
                        resultsMap.setZoom(5);
                    }
                },1000);

                this.result_lat = results[0].geometry.location.lat();
                this.result_lng = results[0].geometry.location.lng();
            } else {
                alert('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    placeMarker(location) {
        if (this.marker) {
            this.marker.setPosition(location);
        } else {
            this.marker = new google.maps.Marker({
                position: location,
                map: map,
                draggable: true
            });
        }
    }

    getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
        let R = 6371; // Radius of the earth in km
        let dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        let dLon = this.deg2rad(lon2-lon1);
        let a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        let d = R * c; // Distance in km
        return d;
    }

    deg2rad(deg) {
        return deg * (Math.PI/180)
    }
}